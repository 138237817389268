import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadAttachmentButton from '../../components/DownloadAttachment/DownloadAttachmentButton';

interface GenericPopupProps {
  open: boolean;
  onClose: () => void;
  onApprove: () => void;
  onReject: () => void;
  data: any | null; 
  renderContent: (data: any | null) => React.ReactNode; 
}

const GenericPopup: React.FC<GenericPopupProps> = ({ open, onClose, onApprove, onReject, data, renderContent }) => {
  const [imageOpen, setImageOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.classList.add("blur-background");
    } else {
      document.body.classList.remove("blur-background");
    }
    
    return () => {
      document.body.classList.remove("blur-background");
    };
  }, [open]);

  const handleShowAttachment = () => {
    setImageOpen(true);
  };

  const handleCloseImage = () => {
    setImageOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth sx={{ overflow: 'hidden' }}>
        <DialogTitle sx={{ backgroundColor: '#3f51b5', color: '#fff', position: 'relative', overflow: 'hidden' }}>
          <Typography variant="h6">Deposit Request Details</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: 3, overflow: 'hidden' }}>
          {renderContent(data)}
          {data?.remittanceCertificate && (
            <Button onClick={handleShowAttachment} variant="outlined" color="primary" sx={{ marginTop: 2 }}>
              Show Attachment
            </Button>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', overflow: 'hidden' }}>
          {data?.remittanceCertificate && (
            <DownloadAttachmentButton fileUrl={data.remittanceCertificate} />
          )}
          <div>
            <Button onClick={onReject} color="error" variant="contained" sx={{ marginRight: 1 }}>
              Reject
            </Button>
            <Button onClick={onApprove} color="success" variant="contained">
              Approve
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      
      <Dialog open={imageOpen} onClose={handleCloseImage} maxWidth="sm" fullWidth sx={{ overflow: 'hidden' }}>
        <DialogTitle sx={{ backgroundColor: '#3f51b5', color: '#fff', position: 'relative', overflow: 'hidden' }}>
          <Typography variant="h6">Attachment</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseImage}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: 3, overflow: 'hidden' }}>
          {data?.remittanceCertificate ? (
            <img 
              src={data.remittanceCertificate} 
              alt="Remittance Certificate" 
              style={{ width: '100%', height: 'auto' }} 
            />
          ) : (
            <Typography>No attachment available</Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GenericPopup;
