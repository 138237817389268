import React from "react";
import { View } from "@aws-amplify/ui-react";
import Menuepage from "./Menuepage";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
// import { lightBlue } from "@mui/material/colors";

const Menue = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  // Function to handle button click and navigate to the "Items" route
  const handleButtonClick = () => {
    navigate("/Items"); // Use navigate to go to the "/Items" route
  };

  return (
    <>
      {/* Header section */}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h2>Add Item</h2>
        {/* Button with click event to navigate */}
        <button
          style={{
            backgroundColor:"#3498db",
            color:"white",
            border: "none",
            borderRadius: "4px",
            padding: "10px 20px",
            cursor: "pointer"
          }}
          onClick={handleButtonClick} // Call handleButtonClick when the button is clicked
        >
          Items
        </button>
      </div>

      {/* Main content section */}
      <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth={{ base: "100%", large: "100%" }}
        padding="1rem"
        maxHeight="100%"
      >
        <br />
        <Menuepage />
      </View>
    </>
  );
};

export default Menue;
