import React from 'react';
import { Button } from '@mui/material';
import { saveAs } from 'file-saver';

interface DownloadAttachmentButtonProps {
  fileUrl: string;
}

const DownloadAttachmentButton: React.FC<DownloadAttachmentButtonProps> = ({ fileUrl }) => {
  const handleDownload = () => {
    saveAs(fileUrl);
  };

  return (
    <Button 
      onClick={handleDownload} 
      variant="contained" 
      color="primary" 
      sx={{ marginLeft: 2 }}
    >
      Download Attachment
    </Button>
  );
};

export default DownloadAttachmentButton;
