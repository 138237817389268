import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useLoading } from '../../contexts/LoadingContext';
import { toast } from 'react-toastify';
import { getCountryList, register } from '../../Common/SharedApis/Auth'; // Adjust import path as needed
import './SignUp.css';
import { useNavigate } from 'react-router-dom';

interface Country {
  countryId: number;
  name: string;
  code: string;
  flag: string;
  active: boolean;
}

const SignUp: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('phone');
  const [countryRegion, setCountryRegion] = useState<string | ''>('');
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [countries, setCountries] = useState<Country[]>([]);
  const {setIsLoading } = useLoading();
  const [error, setError] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const fetchCountries = async () => {
      setIsLoading(true); // Start loading state
      try {
        const countryId = 0; // Adjust this if needed
        const countryList = await getCountryList(countryId);
        setCountries(countryList);
        setIsLoading(false);
      } catch (error) {
        setError('Failed to fetch countries');
        setIsLoading(false);
      }
    };

    fetchCountries();
  }, [setIsLoading]);

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleCountryChange = (selectedOption: any) => {
    const selectedCountryName = selectedOption?.value;
    setCountryRegion(selectedCountryName);

    // Find the selected country and update the phone code
    const selectedCountry = countries.find(country => country.name === selectedCountryName);
    if (selectedCountry) {
      setPhoneCode(selectedCountry.code);
    }
  };

  const handleModify = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError(null);

    // Prepare the model object for the API call with hardcoded first and last names
    const model = {
      Email: email,
      Password: newPassword,
      ConfirmPassword: confirmPassword,
      PhoneNumber:phoneNumber, // Combine code and phone number
      FirstName: 'Muhammad', // Hardcoded first name
      LastName: 'Azeem', // Hardcoded last name
      CountryId: countries.find(country => country.name === countryRegion)?.countryId || 0,
    };

    try {
      const result = await register(model);
      toast.success('Registration successful!');
      console.log('Registration successful:', result); // Log registration result
    } catch (error) {
      setSubmitError('Failed to register. Please try again.');
      toast.error('Failed to register. Please try again.');
      console.error('Registration error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  const countryOptions = countries.map(country => ({
    value: country.name,
    label: (
      <div className="country-option">
        <img
          src={country.flag}
          alt={country.name}
          className="country-flag"
        />
        {country.name} ({country.code})
      </div>
    ),
  }));
  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <div className="forgot-password-container">
      <div className="forgot-password-header">
        <button className="back-button" onClick={handleBackClick}>
          &larr;
        </button>
      </div>
      <h2 className="forgot-password-title">Register</h2>
      <div className="tab-switch">
        <button
          className={selectedTab === 'phone' ? 'active' : ''}
          onClick={() => handleTabChange('phone')}
        >
          Phone Num
        </button>
        <button
          className={selectedTab === 'email' ? 'active' : ''}
          onClick={() => handleTabChange('email')}
        >
          Email
        </button>
      </div>
      <form className="forgot-password-form" onSubmit={handleModify}>
        {selectedTab === 'phone' && (
          <>
            <div className="input-group">
              <label>Country/Region</label>
              <Select
                options={countryOptions}
                onChange={handleCountryChange}
                placeholder="Please select a country/region"
                isClearable
                className="country-select"
                classNamePrefix="select"
                isSearchable // Enable searching
              />
            </div>
            <div className="input-group">
              <label>Phone Num</label>
              <div className="phone-input">
                <input
                  type="text"
                  placeholder="Code"
                  className="code-input"
                  value={phoneCode}
                  readOnly
                />
                <input
                  type="text"
                  placeholder="Please enter Phone"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
            </div>
          </>
        )}
        {selectedTab === 'email' && (
          <div className="input-group">
            <label>Country/Region</label>
            <Select
              options={countryOptions}
              onChange={handleCountryChange}
              placeholder="Please select a country/region"
              isClearable
              className="country-select"
              classNamePrefix="select"
              isSearchable // Enable searching
            />
            <label>Email</label>
            <input
              type="email"
              placeholder="Please enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        )}
        <div className="input-group">
          <label>New Password</label>
          <input
            type="password"
            placeholder="Please enter New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label>Confirm New Password</label>
          <input
            type="password"
            placeholder="Please enter the new password again"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        {submitError && <div className="error-message">{submitError}</div>}
        <button type="submit" className="modify-button" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Register'}
        </button>
        <div>
          <a
            href="/"
            className="forgot-password-link"
            style={{ float: 'right', textDecoration: 'none', color: '#007bff' }}
          >
            Already Have an Account?
          </a>
        </div>


      </form>
    </div>
  );
};

export default SignUp;
