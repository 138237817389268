import React, { useState, useEffect } from "react";
import "./Menue.css";
import {
  Button,
  Flex,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import { Editor } from "@tinymce/tinymce-react";

// Define initial form values
const initialValues = {
  Itemcode: "",
  Itemname: "",
  Category: "", // Use category ID instead of string
  Price: "",
  message: "",
  image: "",
};
interface Category {
  categoryId: number;
  categoryName: string;
}

const Menuepage = () => {
  const [values, setValues] = useState(initialValues);
  const [descriptionError, setDescriptionError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesState, setCategories] = useState<Category[]>([]);
  const authToken = localStorage.getItem("authToken");
  const [selectedImagePath, setSelectedImagePath] = useState('');
  // Fetch categories from the API when the component mounts
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://restaurantapi.fruitcity.pk/api/Category/GetCategoryByRestaurants?RestaurantId=1015",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Request failed with status: ${response.status}`);
        }

        const data = await response.json();
        // Set the fetched categories in the state
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [authToken]); // Include authToken in the dependency array

  // Handle input field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  // Handle description change
  const handleDescriptionChange = (content) => {
    setValues({
      ...values,
      message: content,
    });
  };

  // Handle image file change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setValues({
      ...values,
      image: file,
    });
    setSelectedImagePath(file ? file.name : ''); // Update selectedImagePath
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!values.message.trim()) {
      setDescriptionError(true);
      setIsLoading(false);
      return;
    }

    setDescriptionError(false);

    const formData = new FormData();
    formData.append("item_id", String(values.Itemcode));
    formData.append("item_barCode", values.Itemcode);
    formData.append("item_name", values.Itemname);
    formData.append("item_price", String(values.Price));
    formData.append("item_description", values.message);
    formData.append("Cat_id", String(values.Category)); // Use category ID
    formData.append("restaurant_id", "1015");
    formData.append("ImageFile", values.image);
    formData.append("isVarient", "false");
    formData.append("isDeal", "false");
    formData.append("HasToppings", "false");

    try {
      const response = await fetch(
        "https://restaurantapi.fruitcity.pk/api/Items/AddRestaurantItems",
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("API response:", responseData);

      // Reset the form fields to their initial values
      setValues(initialValues);
    } catch (error) {
      console.error("Error submitting form data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Flex as="form" direction="column" width="100%" onSubmit={handleSubmit}>
        {isLoading && <div>Loading...</div>}

        <TextField
          value={values.Itemcode}
          onChange={handleInputChange}
          name="Itemcode"
          maxWidth="25%"
          label={
            <Text>
              Item Code
              <Text as="span" fontSize="0.8rem" color="red">
                *
              </Text>
            </Text>
          }
          type="text"
          isRequired={true}
        />

        <TextField
          value={values.Itemname}
          onChange={handleInputChange}
          name="Itemname"
          label={
            <Text>
              Item Name
              <Text as="span" fontSize="0.8rem" color="red">
                *
              </Text>
            </Text>
          }
          type="text"
          isRequired={true}
        />

        {/* Use a standard HTML select element for the category dropdown */}
        <label htmlFor="category">
          Category
          <Text as="span" fontSize="0.8rem" color="red">
            *
          </Text>
        </label>
        <select
          id="category"
          name="Category"
          value={values.Category}
          onChange={handleInputChange}
          required
        >
          <option value="">Select a category</option>
          {categoriesState.map((Category) => (
            <option key={Category.categoryId} value={Category.categoryId}>
              {Category.categoryName}
            </option>
          ))}
        </select>

        <TextField
          value={values.Price}
          onChange={handleInputChange}
          name="Price"
          maxWidth="25%"
          label={
            <Text>
              Price
              <Text as="span" fontSize="0.8rem" color="red">
                *
              </Text>
            </Text>
          }
          type="number"
          isRequired={true}
        />

<div className="upload-container">
      <label htmlFor="image" className="upload-button">
        <span className="button-text">Upload Image</span>
        <input
          type="file"
          id="image"
          name="image"
          accept="image/*"
          onChange={handleImageChange}
          className="file-input"
        />
      </label>
      <p id="selected-image-path">{selectedImagePath}</p>
    </div>

        <Text>
          Description
          <Text as="span" fontSize="0.8rem" color="red">
            {descriptionError && " * "}
          </Text>
        </Text>

        <div className="dialogue-box">
          <Editor
            init={{
              height: 300,
              menubar: true,
              branding: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "selectall | " +
                "undo redo | " +
                "cut copy paste | " +
                "bold italic underline strikethrough | " +
                "alignleft aligncenter alignright alignjustify | " +
                "fontselect fontsizeselect | " +
                "forecolor backcolor | " +
                "bullist numlist | " +
                "outdent indent | " +
                "blockquote subscript superscript | " +
                "link unlink | " +
                "table | " +
                "image media | " +
                "removeformat | help",
            }}
            onEditorChange={handleDescriptionChange}
          />
        </div>

        <Button
          type="submit"
          variation="primary"
          width={{ base: "50%", large: "25%" }}
          style={{ marginLeft: "auto" }}
        >
          Submit
        </Button>
      </Flex>
    </>
  );
};

export default Menuepage;
