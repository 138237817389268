import React, { useEffect, useState } from "react";
import { View } from "@aws-amplify/ui-react";
import Pagination from "@mui/material/Pagination";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { TextField, InputAdornment, CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { getAllUsers, GetAllUsersParams } from "../../Common/SharedApis/Auth";
import "./User.css";

interface User {
  id: number;
  userName: string;
  email: string;
  phoneNumber?: string;
  emailConfirmed: boolean;
  firstName: string;
  lastName: string;
  creationDate: string;
  lockoutEnd?: string;
  countryName?: string;
  roles?: string;
}

const Userlist: React.FC = () => {
  const [orders, setOrders] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10); 
  const [searchTerm, setSearchTerm] = useState<string>(""); 
  const navigate = useNavigate();

  const fetchOrders = async (page: number, searchTerm: string) => {
    setLoading(true);
    try {
      const params: GetAllUsersParams = {
        IsActive: true,
        CountryId: "",
        IsEmailConfirmed: false,
        Role: "",
        SearchTerm: searchTerm,
        pageNumber: page,
        pageSize: pageSize,
      };

      const response = await getAllUsers(params);

      const totalRows = response.totalRows || 0;
      setOrders(response.users);

      const calculatedTotalPages = Math.ceil(totalRows / pageSize);
      setTotalPages(calculatedTotalPages > 0 ? calculatedTotalPages : 10);

    } catch (error) {
      console.error("Failed to fetch orders:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders(page, searchTerm);
  }, [page, searchTerm]);

  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", flex: 1, headerClassName: 'header-bold' },
    { field: "userName", headerName: "Username", flex: 1 },
    { field: "email", headerName: "Email", flex: 2 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
    {
      field: "emailConfirmed",
      headerName: "Email Confirmed",
      flex: 1,
      renderCell: (params) => (params.value ? "Yes" : "No"),
    },
    { field: "firstName", headerName: "First Name", flex: 1 },
    { field: "lastName", headerName: "Last Name", flex: 1 },
    // {
    //   field: "creationDate",
    //   headerName: "Creation Date",
    //   flex: 2,
    //   renderCell: (params) => new Date(params.value).toLocaleString(),
    // },
    // {
    //   field: "lockoutEnd",
    //   headerName: "Lockout End",
    //   flex: 2,
    //   renderCell: (params) =>
    //     params.value ? new Date(params.value).toLocaleString() : "N/A",
    // },
    { field: "countryName", headerName: "Country Name", flex: 1 },
    // { field: "roles", headerName: "Roles", flex: 2 },
  ];

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(1); // Reset to the first page when search term changes
  };

  return (
    <View
   
    >
      <h2>User List</h2>

      <div className="search-container-right">
        <TextField
          label="Search By UserName"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <br />
      {loading ? (
        <div className="loading-container">
          <CircularProgress />
          <p>Loading, please wait...</p>
        </div>
      ) : (
        <div>
          <div style={{ height: 600, width: "100%" }}>
            <DataGrid
              rows={orders}
              columns={columns}
              pagination
              paginationMode="server"
              disableRowSelectionOnClick
              hideFooter={true}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: pageSize,
                  },
                },
              }}
            />
          </div>
          <div className="pagination">
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              siblingCount={1}
              boundaryCount={1}
            />
            <div className="page-info">
              Page {page} of {totalPages}
            </div>
          </div>
        </div>
      )}
    </View>
  );
};

export default Userlist;
