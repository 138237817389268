import React from "react";
import { Icon } from "@aws-amplify/ui-react";

import {
  MdDashboard,
  // MdModeEditOutline,
  MdOutlineTrackChanges,
  MdOutlineLogout,
  MdOutlineSettings,
  MdOutlineMenu,
  MdOutlineCategory,
  MdOutlineSupervisedUserCircle,
  MdOutlineCurrencyExchange,
  MdOutlineMoney
  // MdOutlineLogin
} from "react-icons/md";

export const baseConfig = {
  projectLink: "/", // GitHub link in the navbar
  docsRepositoryBase: "", // base URL for the docs repository
  titleSuffix: "",
  search: true,
  header: true,
  headerText: "",
  footer: true,
  footerText: (
    <>
      {/* <span>
        © MIT {new Date().getFullYear()}, Made with ❤️ by {""}
        <a href="https://github.com/mrtzdev" target="_blank" rel="noreferrer">
          Mrtzdev
        </a>
      </span> */}
    </>
  ),

  logo: (
    <>
      <img
        src={process.env.PUBLIC_URL + "/logo.png"}
        alt="logo"
        width="30"
        height="22"
      />
    </>
  ),
};

/// Navigation sidebar
export const appNavs = [
  {
    eventKey: "dashboard",
    icon: <Icon as={MdDashboard} />,
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    eventKey: "Deposite",
    icon: <Icon as={MdOutlineMoney} />,
    title: "Deposite",
    to: "/Deposite",
  },


  // {
  //   eventKey: "tables",
  //   icon: <Icon as={MdOutlineTrackChanges} />,
  //   title: "Tables",
  //   to: "/tables",
  //   children: [
  //     {
  //       eventKey: "basic-table",
  //       title: "Basic Table",
  //       to: "/tables",
  //     },
  //     {
  //       eventKey: "users",
  //       title: "Users Table",
  //       to: "/users-table",
  //     },
  //   ],
  // },
  // {
  //   eventKey: "forms",
  //   icon: <Icon as={MdModeEditOutline} />,
  //   title: "Forms",
  //   to: "/forms",
  //   children: [
  //     {
  //       eventKey: "form-basic",
  //       title: "Basic",
  //       to: "/forms",
  //     },
  //     {
  //       eventKey: "form-wizard",
  //       title: "Edit Form",
  //       to: "/edit-form",
  //     },
  //   ],
  // },


  {
    eventKey: "Orders",
    icon: <Icon as={  MdOutlineSupervisedUserCircle} />,
    title: "Users",
    to: "/Users",
    
  },
  {
    eventKey: "Trade",
    icon: <Icon as={MdOutlineCurrencyExchange} />,
    title: "Trade",
    to: "/Trades",
  },
  {
    eventKey: "Settings",
    icon: <Icon as={MdOutlineSettings} />,
    title: "Settings",
    to: "/Settings",
    
  },
  {
    eventKey: "Logout",
    icon: <Icon as={MdOutlineLogout} />,
    title: "Logout",
    to: "/Logout",
    
  },
  // {
  //   eventKey: "Insight",
  //   icon: <Icon as={MdOutlineInsights} />,
  //   title: "Insight",
  //   to: "/Insight",
    
  // },
  // {
  //   eventKey: "Supports",
  //   icon: <Icon as={MdOutlineSupport} />,
  //   title: "Supports",
  //   to: "/Supports",
    
  // },
  // {
  //   eventKey: "Config",
  //   icon: <Icon as={MdModeEditOutline} />,
  //   title: "Config",
  //   to: "/Config",
    
  // },

  // {
  //   eventKey: "profile",
  //   icon: <Icon as={MdAccountBox} />,
  //   title: "Profile",
  //   to: "/profile",
  // },
];
