import React, { createContext, useEffect, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import createSignalRConnection from '../contexts/signalRService';

const SignalRContext = createContext<{ connection: signalR.HubConnection | null, startConnection: () => void } | null>(null);

export const useSignalR = () => useContext(SignalRContext);

const getExponentialBackoffDelay = (attempt: number) => {
  const baseDelay = 5000; // 5 seconds
  return Math.min(baseDelay * 2 ** attempt, 60000); // Maximum 1 minute delay
};

export const SignalRProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
  const [retryAttempt, setRetryAttempt] = useState(0);
 // const [statusMessage, setStatusMessage] = useState<string>("Connecting to the server...");
 // const [showStatusBar, setShowStatusBar] = useState<boolean>(true);


  const startConnection = () => {
    debugger;
    const token = localStorage.getItem('authToken')?.toString() || "";
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    const isTokenValid = token && tokenExpiration && new Date(tokenExpiration) > new Date();

    console.log('startConnection method call for signalR', token);
    console.log('tokenExpiration', tokenExpiration);
    console.log('isTokenValid', isTokenValid);

    if (token != "" && !isTokenValid) {
      return;
    }
    console.log('createSignalRConnection', token);
    const newConnection = createSignalRConnection(token);
    if (!newConnection) {
      return;
    }

    setConnection(newConnection);

    const tryConnect = () => {
    newConnection.start()
      .then(() => {
        console.log("Connected to SignalR Hub/ Token=", localStorage.getItem('authToken')+ "Exp Token: "+localStorage.getItem('tokenExpiration')+" "+localStorage.getItem('userName')+ "UserId:"+ localStorage.getItem('userId'))
     //   setStatusMessage("Connected to the server.");
        setTimeout(() => "", 3000);
}     )
      .catch(err => {
        console.error("Error connecting to SignalR Hub: ", err);
        setRetryAttempt(prev => prev + 1);
        const delay = getExponentialBackoffDelay(retryAttempt);
     //   setStatusMessage(`Failed to connect. Retrying in ${delay / 1000} seconds...`);
        toast.error("Failed to connect to the server. Retrying...");
        setTimeout(tryConnect, delay);
      });
    };
    tryConnect();

      newConnection.on("TradeResult", (type,message) => {
        toast.success("Trade Result: " + message + " "+type);
        console.log("Trade result received: ", message);
        localStorage.setItem("TradeResult",message + " "+type);
      });

      newConnection.on("broadcastmessage", (type, payload) => {
        console.log("Notification received: ", type, payload);
        toast.success("Notification: " + type + " "+ payload);
    });

    newConnection.on("tradeCreated", (type, payload) => {
      console.log("tradeCreated received: ", type, payload);
      toast.success("tradeCreated: " + type + " "+ payload);
  });


    newConnection.onreconnecting((error) => {
      console.warn(`Connection lost due to error "${error}". Reconnecting...`);
    //  setStatusMessage("Connection lost. Reconnecting...");
    //  setShowStatusBar(true); // Show status bar during reconnection
      toast.warn("Connection lost. Reconnecting...");
    });

    newConnection.onreconnected((connectionId) => {
      console.log(`Connection reestablished. Connected with connectionId ${connectionId}.`);
    //  setStatusMessage("Reconnected to the server.");
      toast.success("Reconnected to the server.");
      setTimeout(() => "", 3000); // Hide status bar after 3 seconds
      setRetryAttempt(0); // Reset retry attempt on successful reconnection
    });

    newConnection.onclose(() => {
      console.warn("Connection closed.");
   //   setStatusMessage("Connection closed. Trying to reconnect...");
   //   setShowStatusBar(true); // Show status bar when the connection is closed
      toast.error("Connection closed. Trying to reconnect...");
      setRetryAttempt(prev => prev + 1);
      setTimeout(tryConnect, getExponentialBackoffDelay(retryAttempt));
    });
  };

  useEffect(() => {
    startConnection();
  }, []);

  return (
    <SignalRContext.Provider value={{ connection, startConnection }}>
      {children}
    </SignalRContext.Provider>
  );
};
