import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { fetchPendingTrades } from '../../Common/SharedApis/Trade';
import { useSignalR } from '../../contexts/SignalRProvider';
import { useNavigate } from 'react-router-dom';
import './Trade.css';

const Trade = () => {
    const [trades, setTrades] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { connection } = useSignalR()!;
    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            const result = await fetchPendingTrades();
            setTrades(result);
            setLoading(false);
        };
        loadData();
    }, []);

    useEffect(() => {
        if (connection) {
            connection.on("tradeCreated", async (newTrade) => {
                const result = await fetchPendingTrades();
                setTrades(result);
            });
        }

        return () => {
            if (connection) {
                connection.off("tradeCreated");
            }
        };
    }, [connection]);

    return (
        <div className="trade-container">
            <h2>Pending Trades</h2>
            {loading ? (
                <p>Loading...</p>
            ) : trades.length === 0 ? (
                <p>No pending trades found.</p>
            ) : (
                trades.map((trade) => (
                    <TradeCard key={trade.tradeId} trade={trade} navigate={navigate} />
                ))
            )}
        </div>
    );
};

const TradeCard = ({ trade, navigate }) => {
    const initialTime = 120; // Set your default duration (2 mins in this case)
    const [timeLeft, setTimeLeft] = useState<number>(initialTime);
    const timePercentage = (timeLeft / initialTime) * 100;

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        } else if (timeLeft === 0) {
            navigate('/result-page');
        }
    }, [timeLeft, navigate]);

    const getStrokeColor = () => {
        if (timePercentage > 50) return '#4caf50'; // Green for > 50%
        if (timePercentage > 20) return '#ffeb3b'; // Yellow for > 20%
        return '#f44336'; // Red for <= 20%
    };

    const handleContinue = () => {
        navigate('/result-page');
    };

    const handleClose = () => {
        navigate(-1); // Go back to the previous page
    };

    return (
        <Card className="trade-card">
            <CardContent className="trade-card-content">
                <Box className="trade-user-info">
                    <Box className="trade-avatar">
                        <Typography>User Icon</Typography>
                    </Box>
                    <Typography variant="body1"><strong>{trade.userName}</strong></Typography>
                    <Typography variant="body2" color="text.secondary">{trade.email}</Typography>
                </Box>

                <Box className="trade-details">
                    <Typography variant="body2" color="text.secondary">Trading Date (UTC): {new Date(trade.tradingDateTimeUTC).toLocaleString()}</Typography>
                    <Typography variant="body2" color="text.secondary">Trading Date (PST): {formatDateToPST(trade.tradingDateTimePKT)}</Typography>
                    <Typography variant="h6">{trade.digitalCoinName.toUpperCase()} - ${trade.tradingAmount}</Typography>
                    <Typography variant="body2">Expected Profit: ${trade.expectedProfit}</Typography>
                    <Box className="trade-result-buttons">
                        <Button variant="outlined" color="success">Win</Button>
                        <Button variant="outlined" color="error">Loss</Button>
                    </Box>
                </Box>

                <Box className="trade-timer">
                    <svg className="timer-svg" viewBox="0 0 36 36">
                        <path
                            className="circle-bg"
                            d="M18 2.0845
                               a 15.9155 15.9155 0 0 1 0 31.831
                               a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path
                            className="circle"
                            strokeDasharray={`${timePercentage}, 100`}
                            stroke={getStrokeColor()}
                            d="M18 2.0845
                               a 15.9155 15.9155 0 0 1 0 31.831
                               a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <text x="18" y="20.35" className="timer-text">
                            {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
                        </text>
                    </svg>
                    <Box className="timer-footer">
                        <Button variant="contained" color="secondary" onClick={handleClose}>Close</Button>
                        <Button variant="contained" color="primary" onClick={handleContinue}>Continue</Button>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

 // Function to convert UTC time to Pakistan Standard Time (PST)
 const formatDateToPST = (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
        timeZone: 'Asia/Karachi',
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    return new Date(Date.parse(date)).toLocaleString('en-US', options); // Ensure proper parsing of date string

};

export default Trade;
