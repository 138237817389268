import apiBaseUrl from '../ApiBaseUrl';

const handleResponse = async (response: Response) => {
    if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
};
var token = localStorage.getItem('authToken')?.toString();

export const createTrade = async (trade: any) => {
    var token = localStorage.getItem('authToken')?.toString();
    console.log('token',token);
    console.log('model',trade);
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Trade/CreateTrade`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                 Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(trade),
        });
        const data = await handleResponse(response);
        console.log('Trade created successfully:', data);
        return data;
    } catch (error) {
        console.error('Error creating trade:', error);
        throw error;
    }
};

export const fetchPendingTrades = async () => {
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Trade/GetPendingTrades`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            const errorBody = await response.text();
            console.error('Response not OK:', response.statusText);
            console.error('Error response body:', errorBody);

            if (response.status === 404 && errorBody.includes("No pending trades found.")) {
                return []; // Return an empty array if no pending trades are found
            }
            
            return []; // Return empty array on other errors
        }

        const data = await response.json();
        console.log('Fetched data:', data); // Log the entire response data

        return Array.isArray(data) ? data : data.trades || []; // Ensure it returns an array
    } catch (error) {
        console.error('Error fetching data:', error);
        return []; // Return empty array on error
    }
};
