import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSignalR } from '../contexts/SignalRProvider';

const ProtectedRoute = () => {
    const token = localStorage.getItem('authToken');
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    const isTokenValid = token && tokenExpiration && new Date(tokenExpiration) > new Date();
    const { connection, startConnection } = useSignalR() || {}; // Destructure connection and startConnection
    const [hasConnected, setHasConnected] = useState(false); // Track connection status

    useEffect(() => {
        console.log('tokenExpiration',tokenExpiration);
        console.log('isTokenValid',isTokenValid);
        if (isTokenValid && startConnection && !hasConnected) {
         
            if (!connection || connection.state === 'Disconnected') {             
                startConnection(); // Start SignalR connection
                setHasConnected(true); // Mark connection as established
            }
        }
    }, [isTokenValid, startConnection, connection, hasConnected]);

    if (!isTokenValid) {
        console.log('go to login');
        return <Navigate to="/login" replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
