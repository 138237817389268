import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { CircularProgress, TextField, InputAdornment, Typography, Button } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import { fetchAllDepositRequest, fetchDepositRequestbyid } from "../../Common/SharedApis/DespositMethods";
import GenericPopup from "../../components/Popup/GenericPopup";

// Define the DepositRequest interface
export interface DepositRequest {
  depositId: number;
  userName: string;
  userEmail: string;
  amount: number;
  entryDateTime: string;
  statusId: number;
  isActive: boolean;
  exchangeRate: number;
  remittanceCertificate: string;
  userPhoneNumber: string;
  userFullName: string;
  coinName: string;
  coinLogo: string;
  chainName: string;
  depositFrom: string | null;
  depositTo: string | null;
}

const DepositeRequest: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [depositRequests, setDepositRequests] = useState<DepositRequest[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<DepositRequest | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await fetchAllDepositRequest();
        if (data) {
          const formattedData = data.map((item: DepositRequest, index: number) => ({
            id: item.depositId, // Ensure unique ID
            ...item,
          }));
          setDepositRequests(formattedData);
          setTotalPages(Math.ceil(formattedData.length / pageSize)); // Or use data.total if available
        }
      } catch (error) {
        console.error("Failed to fetch deposit requests:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, [page, pageSize]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = depositRequests.filter((request) =>
    request.userName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleViewClick = async (depositId: number) => {
    try {
      const data = await fetchDepositRequestbyid(depositId);
      if (data) {
        setSelectedRequest(data);
        setIsPopupOpen(true);
      }
    } catch (error) {
      console.error(`Error fetching deposit request by ID: ${depositId}`, error);
    }
  };

  const handleApprove = () => {
    console.log("Approved");
    setIsPopupOpen(false);
  };

  const handleReject = () => {
    console.log("Rejected");
    setIsPopupOpen(false);
  };

  const columns: GridColDef[] = [
    { field: 'depositId', headerName: 'ID', width: 90 },
    { field: 'userName', headerName: 'User Name', width: 150 },
    { field: 'userEmail', headerName: 'User Email', width: 200 },
    { field: 'amount', headerName: 'Amount', width: 100 },
    { field: 'entryDateTime', headerName: 'Date', width: 150 },
    { field: 'statusId', headerName: 'Status ID', width: 120 },
    { field: 'isActive', headerName: 'Active', width: 100 },
    { field: 'exchangeRate', headerName: 'Exchange Rate', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <GridActionsCellItem
          icon={<Button variant="contained" color="primary">View Detail</Button>}
          label="View"
          onClick={() => handleViewClick(params.row.depositId)}
        />
      ),
    },
  ];

  if (loading) {
    return <CircularProgress />;
  }

  const renderPopupContent = (data: DepositRequest | null) => (
    data ? (
      <div>
        <Typography variant="body1">ID: {data.depositId}</Typography>
        <Typography variant="body1">User Name: {data.userName}</Typography>
        <Typography variant="body1">User Email: {data.userEmail}</Typography>
        <Typography variant="body1">Amount: ${data.amount}</Typography>
        <Typography variant="body1">Date: {data.entryDateTime}</Typography>
        <Typography variant="body1">Status ID: {data.statusId}</Typography>
        <Typography variant="body1">Active: {data.isActive ? "Yes" : "No"}</Typography>
        <Typography variant="body1">Exchange Rate: {data.exchangeRate}</Typography>
        {/* <Typography variant="body1">Remittance Certificate: {data.remittanceCertificate}</Typography> */}
        <Typography variant="body1">Phone Number: {data.userPhoneNumber}</Typography>
        <Typography variant="body1">Full Name: {data.userFullName}</Typography>
        <Typography variant="body1">Coin Name: {data.coinName}</Typography>
        <Typography variant="body1">Coin Logo: <img src={data.coinLogo} alt={data.coinName} style={{ width: 50, height: 50 }} /></Typography>
        <Typography variant="body1">Chain Name: {data.chainName}</Typography>
        <Typography variant="body1">Deposit From: {data.depositFrom ?? "N/A"}</Typography>
        <Typography variant="body1">Deposit To: {data.depositTo ?? "N/A"}</Typography>
      </div>
    ) : (
      <Typography variant="body1">No data available</Typography>
    )
  );

  return (
    <div>
      <h2>Deposit Requests</h2>
      <div className="search-container-right">
        <TextField
          label="Search By UserName"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div>
        <DataGrid
          rows={filteredData.slice((page - 1) * pageSize, page * pageSize)}
          columns={columns}
          pagination
          paginationMode="server"
          disableRowSelectionOnClick
          hideFooter={true}
        />
      </div>
      <div className="pagination">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          siblingCount={1}
          boundaryCount={1}
        />
        <div className="page-info">
          Page {page} of {totalPages}
        </div>
      </div>
      <GenericPopup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onApprove={handleApprove}
        onReject={handleReject}
        data={selectedRequest}
        renderContent={renderPopupContent}
      />
    </div>
  );
};

export default DepositeRequest;
