import React from "react";

function Dashboard(){
  return(
    <div>
      Dashborad
    </div>
  )
}

export default Dashboard;