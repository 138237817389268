import React, { useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import { ThemeProvider } from "@aws-amplify/ui-react";
import theme from "./theme";

import Layout from "./components/Layout";
import Dashboard from "./pages/dashboard";
import Profile from "./pages/profile";
import Tables from "./pages/tables";
import UsersTable from "./pages/tables/UsersTablePage";
import Forms from "./pages/forms";
import EditForm from "./pages/forms/EditForm";
import Logins from "../src/pages/Login/Loginpage";
import Menue from "./pages/Menue/Menue";

import SignUp from "./pages/SignUp/SignUp";
import useOnlineStatus from "./contexts/useOnlineStatus";
import StatusBar from "./contexts/StatusBar";
import { SignalRProvider } from "./contexts/SignalRProvider";
import ErrorBoundary from "./contexts/ErrorBoundary";
import Userlist from "./pages/Users/UserList";
import Trade from "./pages/Trades/Trade";
import Deposite from "./pages/Deposites/Deposit";

import ToastProvider from "./contexts/ToastProvider";
import ProtectedRoute from "./contexts/ProtectedRoute";






export default function App() {

  const isOnline = useOnlineStatus();  // Use the custom hook to check online status
  const [showStatusBar, setShowStatusBar] = useState(true);
  const [statusMessage, setStatusMessage] = useState('No internet connection detected. Please check your network settings.');
  const handleCloseStatusBar = () => {
    setShowStatusBar(false);
  };
  
  return (
    <ThemeProvider theme={theme}>
      <div>
      <ToastProvider>
        {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}

<StatusBar message={statusMessage} show={!isOnline}  onClose={handleCloseStatusBar}/>
       <SignalRProvider>
       <ErrorBoundary> 
        
        <Routes>
        <Route index element={<Logins />} />
            <Route path="Signup" element={< SignUp/>} />

         {/* Protected Routes */}
         <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Layout />}>
            <Route path="forms" element={<Forms />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="layout" element={<Layout />} />
            <Route path="tables" element={<Tables />} />
            <Route path="users-table" element={<UsersTable />} />
            <Route path="profile" element={<Profile />} />
            <Route path="Menue" element={<Menue />} />        
            <Route path="Users" element={<Userlist/>} />
            <Route path="Trades" element={<Trade/>} />
            <Route path="Deposite" element={<Deposite/>} />
            <Route path="*" element={<NoMatch />} />
          </Route>
          </Route>
        </Routes>
        </ErrorBoundary>
        </SignalRProvider>
</ToastProvider>
      </div>
    </ThemeProvider>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
